import request from "@/utils/request";

export const getOrderDetailsApi = (data) =>
  request({
    url: `/api/V2/orders/${data}`,
    method: "get",
  });

export const getOrderOptimizeApi = (data) =>
  request({
    url: `/api/V2/orders/${data}/optimize`,
    method: "get",
  });

export const getPriorityListApi = (data) =>
  request({
    url: `/api/V2/orders/hours/${data}`,
    method: "get",
  });

export const getOrderOptionsApi = (data) =>
  request({
    url: `/api/V2/orders/${data}/options`,
    method: "get",
  });

export const uploadOptimizedFile = (id, data) =>
  request({
    url: `/api/V2/orders/${id}/upload`,
    method: "post",
    data,
  });

export const downloadCad = (id) =>
  request({
    url: `/api/V2/orders/${id}/download/cad`,
    method: "post",
  }).then((response) => window.open(response.data, "__blank"));

export const zpanelMove = (id, content) =>
  request({
    url: `/api/V2/orders/${id}/`,
    method: "post",
    content,
  });

export const zpanelSend = (data) =>
  request({
    url: "/api/V2/zpanel/proof",
    method: "post",
    data,
    contentType: "multipart/form-data",
  });

export const updateInventories = (id, data) =>
  request({
    url: `/api/V2/orders/inventories/${id}`,
    method: "put",
    data,
  });

export const updateOptions = (data) =>
  request({
    url: `/api/V2/orders/options`,
    method: "put",
    data,
  });

export const createOrderApi = (data) =>
  request({
    url: `/api/V2/orders-group`,
    method: "post",
    data,
  });

export const getCubbyStatusesById = (id) =>
  request({
    url: `/api/V2/orders/cubby-statuses/${id}`,
    method: "get",
  });

export const getRemakeOrderApi = (id) =>
  request({
    url: `/api/V2/orders/reorder/${id}`,
    method: "get",
  });

export const remakeOrderApi = (id, data) =>
  request({
    url: `/api/V2/orders/reorder/${id}`,
    method: "post",
    data,
  });

export const changeCurrencyApi = (id, data) =>
  request({
    url: `/api/V2/orders-group/currency/${id}`,
    method: "put",
    data,
  });

export const addInstructionApi = (id, data) =>
  request({
    url: `/api/V2/orders/${id}/notes`,
    method: "put",
    data,
  });

export const getVerificationApi = (id) =>
  request({
    url: `/api/V2/orders/${id}/verification`,
    method: "get",
  });

export const sendPreviewApi = (id, data) =>
  request({
    url: `/api/V2/zpanel/move/${id}`,
    method: "post",
    data,
  });

export const getNextOrderApi = () => {
  return request({
    url: `/api/V2/orders/next`,
    method: "get",
  });
};

export const redoFromVerificationApi = (data) =>
  request({
    url: `/api/V2/orders/redo`,
    method: "post",
    data,
  });

export const verifyOrSkipApi = (id, data) =>
  request({
    url: `/api/V2/orders/${id}/verify `,
    method: "post",
    data,
  });

export const verificateChanges = (id, data) =>
  request({
    url: `/api/V2/orders/${id}/verification `,
    method: "post",
    data,
  });
