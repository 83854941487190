/* eslint-disable camelcase */
import useToast from "@/hooks/useToast";
import { ORDER_TYPES } from "@/consts/newOrderFormInputs";
import { isObjectEmpty } from "./variableTypes";

const { openToast } = useToast();
const error = (message = "Product(s) filled uncorrectly") => openToast(message, "danger");

export const orderInfoValidator = (data) => {
  let valid = true;

  const requiredFields = ["name", "phone", "currency", "email", "shipping_type", "service_id"];

  if (data.type === ORDER_TYPES[0].value.toString()) {
    requiredFields.push("seller_id", "store_id");
  }

  if (!requiredFields.every((field) => data[field])) {
    valid = false;
    error(`Order main information must be filled!`);
  }
  return valid;
};

export const editOrderInfoValidator = (data) => {
  let valid = true;
  if (
    !data.name ||
    !data.phone ||
    !data.currency ||
    !data.email ||
    !data.seller_id ||
    !data.store_id ||
    !data.service_id
  ) {
    valid = false;
    error(`Order main information must be filled!`);
  }
  return valid;
};

export const orderAdditionalInfoValidator = (data) => {
  let valid = true;
  if (!data.shipping_cost || !data.tax) {
    valid = false;
    error(`Order tax and shipping cost is not filled!`);
  }
  return valid;
};

export const orderProductsValidator = (products) => {
  let valid = true;
  products.forEach((product, idx) => {
    const index = idx + 1;
    if (
      [product.qty, product.price].some(
        (item) => item === undefined || item === null || item === ""
      )
    ) {
      valid = false;
      error(`Product ${index} required fields are empty!`);
    }

    if (
      (product.productDiscount &&
        (product.productDiscountType === null || !product.productDiscountPrice)) ||
      (product.rectangleDiscount &&
        (product.rectangleDiscountType === null || !product.rectangleDiscountPrice)) ||
      (product.heartDiscount &&
        (product.heartDiscountType === null || !product.heartDiscountPrice)) ||
      (product.lightBaseDiscount &&
        (product.lightBaseDiscountType === null || !product.lightBaseDiscountPrice)) ||
      (product.inscriptionDiscount &&
        (product.inscriptionDiscountType === null || !product.inscriptionDiscountPrice)) ||
      (product.backgroundDiscount &&
        (product.backgroundDiscountType === null || !product.backgroundDiscountPrice)) ||
      (product.notesDiscount &&
        (product.notesDiscountType === null || !product.notesDiscountPrice)) ||
      (product.digitalPreviewDiscount &&
        (product.digitalPreviewDiscountType === null || !product.digitalPreviewDiscountPrice))
    ) {
      valid = false;
      error(`Product ${index} some of discount are empty!`);
    }

    if (product.background && !product.backgroundPrice) {
      valid = false;
      error(`Product ${index} background price are empty!`);
    }
    if (
      (product.notes && (!product.notesText || !product.notesPrice)) ||
      (product.digitalPreview && !product.digitalPreviewPrice)
    ) {
      valid = false;
      error(`Product ${index} section with notes or preview filled uncorrectly!`);
    }
    if (!product.photoLater && !product.mainPhoto && !product.extraPhoto) {
      valid = false;
      error(`Product ${index} section with photos filled uncorrectly!`);
    }
  });
  return valid;
};

export const editOrderProductsValidator = (products) => {
  let valid = true;
  products.forEach((product, idx) => {
    const index = idx + 1;
    if (
      [product.qty, product.price].some(
        (item) => item === undefined || item === null || item === ""
      )
    ) {
      valid = false;
      error(`Product ${index} required fields are empty!`);
    }

    if (
      (product.productDiscount &&
        (product.productDiscountType === null || !product.productDiscountPrice)) ||
      (product.rectangleDiscount &&
        (product.rectangleDiscountType === null || !product.rectangleDiscountPrice)) ||
      (product.heartDiscount &&
        (product.heartDiscountType === null || !product.heartDiscountPrice)) ||
      (product.lightBaseDiscount &&
        (product.lightBaseDiscountType === null || !product.lightBaseDiscountPrice)) ||
      (product.inscriptionDiscount &&
        (product.inscriptionDiscountType === null || !product.inscriptionDiscountPrice)) ||
      (product.backgroundDiscount &&
        (product.backgroundDiscountType === null || !product.backgroundDiscountPrice)) ||
      (product.notesDiscount &&
        (product.notesDiscountType === null || !product.notesDiscountPrice)) ||
      (product.digitalPreviewDiscount &&
        (product.digitalPreviewDiscountType === null || !product.digitalPreviewDiscountPrice)) ||
      (product.popupCardDiscount &&
        (product.popupCardDiscountType === null || !product.popupCardDiscountPrice))
    ) {
      valid = false;
      error(`Product ${index} some of discount are empty!`);
    }

    if (product.background && !product.backgroundPrice) {
      valid = false;
      error(`Product ${index} background price are empty!`);
    }
    if (
      (product.notes && (!product.notesText || !product.notesPrice)) ||
      (product.digitalPreview && !product.digitalPreviewPrice)
    ) {
      valid = false;
      error(`Product ${index} section with notes or preview filled uncorrectly!`);
    }
  });
  return valid;
};

export const orderProductValidateSections = (values) => {
  const about = {
    product: values.product,
    qty: values.qty,
    boxQty: values.boxQty,
    price: values.price,
    clientId: values.clientId,
  };
  const photos = {
    mainPhoto: values.mainPhoto,
    extraPhoto: values.extraPhoto,
    photoLater: values.photoLater,
  };

  return {
    about: isObjectEmpty(about),
    photos: isObjectEmpty(photos),
  };
};

export const newInventoryValidate = (data) => {
  let valid = true;
  if (!data.name || !data.quantity || !data.weight || !data.spot || !data.sorting) {
    valid = false;
    error(`Inventory main information must be filled!`);
  }
  return valid;
};
